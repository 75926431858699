<template>
    <transition appear enter-active-class="animated fadeIn">
        <div v-if="blog">
            <b-loading :is-full-page="false" :active="!blog"/>

            <router-link tag="button" class="button is-primary back-button hvr-icon-back b-shdw-1" :to="{name:'blog'}">
                <i class="fa fa-angle-left hvr-icon"/> Back to Blogs
            </router-link>

            <div class="" oncontextmenu="return false;">

                <div class="box b-shdw-3">
                    <div class="level-item">
                        <h1 class="title is-1 page-title has-text-centered">{{ blog.title }}</h1>
                    </div>

                    <p v-html="blog.content"/>
                    <br>
                    <p class="tag">Posted on {{ $moment(blog.post_date).format('DD/MM/YYYY') }}</p>
                </div>

            </div>

            <!-- Related blogs -->
            <div class="box b-shdw-3 related-blogs">
                <h5 class="title is-5">Related Blogs</h5>

                <div class="columns is-multiline">
                    <div class="column is-half" v-for="b in relatedBlogs" :key="b.id">

                        <a class="" :href="b.url_name">
                            <div class="columns box">
                                <div class="column is-3">
                                    <img class="blog-img" :src="'https://covered2go.co.uk/content-block/images/covered2go/' + b.image_name" alt="blog image">
                                </div>
                                <div class="column">
                                    <h6 class="title rel-blog-title is-6">{{ b.title }}</h6>
                                    <p v-html="b.content.substr(0, 200) + '...'"/>
                                    <p class="tag">Posted on {{ $moment(b.post_date).format('DD/MM/YYYY') }}</p>
                                    <a class="" :href="b.url_name">Read more...</a>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "BlogView",
        data() {
            return {
                blog: null,
                relatedBlogs: []
            }
        },
        props: ['id'],
        mixins: [ contentAPI ],
        methods: {
            ...mapActions({
                setBlogs: 'setBlogs',
            }),
            getRelatedBlogs() {
                let words = this.blog.title.toLowerCase();
                words = words.replace(new RegExp(' in', 'g'), '');
                words = words.replace(new RegExp(' -', 'g'), '');
                const relatedWords = words.split(' ');

                let count = 0;
                this.blogs.forEach(b => {
                    for(let i = 0; i < relatedWords.length; i++) {
                        if(b.content.toLowerCase().includes(relatedWords[i]) && this.blog.id !== b.id && count < 6 && b.status === 'active') {
                            this.relatedBlogs.push(b);
                            count++;
                            break;
                        }
                    }
                });
            }
        },
        computed: {
            ...mapGetters({
                blogs: 'blogs',
            }),
            imgLink() {
                return `https://covered2go.co.uk/content-block/images/covered2go/${this.blog.image_name}`;
            },
            /*blogStyle() {
                return {
                    backgroundImage: `linear-gradient(white, rgba(0, 0, 0, 0.1)), url(${this.imgLink})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                };
            }*/
        },
        mounted() {
            // if(!this.blogs) {
                // Get blog posts from API
                // this.getBlog('*').then((res) => {
                //     this.setBlogs(JSON.parse(res.data));
                //     this.blog = this.blogs.find(b => b.url_name === this.id);
                //     this.getRelatedBlogs();
                // });

                this.getBlog('*').then((res) => {
                    const fetchedBlogs = JSON.parse(res.data);
                    const blogList = fetchedBlogs.filter(b => {
                        return b.status === 'active';
                    });
                    this.setBlogs(blogList);
                    this.blog = this.blogs.find(b => b.url_name === this.id);
                    // this.total = blogList.length;
                    // this.isLoading = false;
                });
            // } else {
            //     // Get stored blog posts from memory
            //     this.blog = this.blogs.find(b => b.url_name === this.id);
            //     this.getRelatedBlogs();
            // }

        }
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 20px 0 30px 0;
    }

    #blog-img {
        position: relative;
        opacity: 0.1;
    }
    .back-button {
        margin-bottom: 20px;
    }
    .related-blogs {
        margin: 20px 0 0 0;
        img {
            height: 100px;
            width: 100px;
            object-fit: cover;
        }
        .tag {
            margin-top: 10px;
        }
        .rel-blog-title {
            margin-bottom: 0px;
        }
        .box {
            margin: 2px;
        }
    }

</style>